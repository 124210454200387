import {
  EntityDetails,
  Product,
  TemplateWidget,
  WidgetProduct,
  BlogWidgetAttribute,
  WidgetCarouselVariant,
  ProductCollectionStyle,
  WidgetRegionLink,
  WidgetCategoryLink,
  FiltersListingPreviewEntityType,
  PlpProduct,
  BlogTemplateData,
} from '@/types'
import Container from '@/components/Container'
import dynamic from 'next/dynamic'

// Widgets
import ListingPreview from '@/components/widgets/ListingPreview'
import FiltersListingPreview from '@/components/widgets/FiltersListingPreview'
import ProductCollection from '@/components/ProductCollection'
import { Spacing, FlexibleHeading as Heading, Body } from '@big-red-group/storefront-common-ui'
import CampaignPromo from '@/components/CampaignPromo'
import HomeHero from '@/components/HomeHero'
import LargeTileSwiper from '@/components/LargeTileSwiper'
import FeaturedLinksSwiper from '@/components/FeaturedLinksSwiper'
import EditorialSwiper from '@/components/widgets/EditorialSwiper'
import BlogHero from '@/components/BlogHero'
import BlogImage from '@/components/widgets/BlogImage'
import PromoImage from '@/components/widgets/PromoImage'
import BlogDetails from '@/components/widgets/BlogDetails'
import ImageFan from '@/components/widgets/ImageFan'
import ImageGridFour from '@/components/widgets/ImageGridFour'
import ImageGridThree from '@/components/widgets/ImageGridThree'
import ImageTextBlock from '@/components/ImageTextBlock'
import CtaButton from '@/components/widgets/CtaButton'
import PlpHeader from '@/components/widgets/PlpHeader'
import CustomLinksSwiper from '@/components/CustomLinksSwiper'
import FeaturedProductTabs from '@/components/widgets/FeaturedProductTabs'
import ContributorShowcase from '@/components/ContributorShowcase'
import ContentContributorHeader from '@/components/widgets/ContentContributorHeader'
const Seo = dynamic(() => import('@/components/SeoContent'), { ssr: false })
import RegionLinks from '@/components/widgets/RegionLinks'

// Converts an array of widget products to an array of products
export function toProduct(widgetProducts: WidgetProduct[]): Product[] {
  return widgetProducts?.map((widgetProduct: WidgetProduct, index: number) => {
    const product: Product = {
      id: index,
      title: widgetProduct.name,
      supplier: widgetProduct.supplierName,
      canonicalRegionUrlSegment: '',
      fromPrice: widgetProduct.fromPrice,
      price: widgetProduct.retailPrice,
      levy: widgetProduct.levy,
      rating: widgetProduct.rating,
      region: '',
      image: widgetProduct.imageUrl,
      badges: widgetProduct.decorators,
      url: `/en${widgetProduct.urlPath}`,
      inclusions: [],
      hotDeals: widgetProduct.hotDealMessage,
    }
    return product
  })
}

export function plpProductToWidgetProduct(products: PlpProduct[]): WidgetProduct[] {
  return products?.map((plpProduct: PlpProduct, index: number) => {
    const product: WidgetProduct = {
      name: plpProduct.name,
      supplierName: plpProduct.operatorName,
      urlPath: `/${plpProduct.canonicalRegionUrlSegment}/${plpProduct.urlSegment}`,
      fromPrice: plpProduct.fromPrice,
      retailPrice: plpProduct.retailPrice,
      levy: plpProduct.levy,
      imageUrl: plpProduct.images?.[0],
      decorators: plpProduct.decorators,
      hotDealMessage: plpProduct?.hotDeals?.[0]?.message,
      rating: plpProduct.rating,
    }
    return product
  })
}

export const getFilterDataCategoryWidget = (widget: TemplateWidget) => {
  const { name } = widget
  if (name != 'category-links') return
  return widget.categories
}

export const getFilterDataRegionWidget = (widget: TemplateWidget) => {
  const { name } = widget
  if (name != 'region-links') return
  return widget.states
}

export const getFiltersListingPreview = (
  entityType: string,
  entityDetails: EntityDetails,
  regions: WidgetRegionLink[] | undefined,
  categories: WidgetCategoryLink[] | undefined,
  handlerKey?: string,
  region?: string
) => {
  const { name: entityName } = entityDetails

  let listingTitle
  let slicedProducts
  switch (entityType) {
    case 'REGION':
      slicedProducts = entityDetails.products
      listingTitle = `Explore ${entityName} adventures`
      break
    case 'CATEGORY':
      slicedProducts = entityDetails.products
      listingTitle = ''
      break
    case 'STATE':
      slicedProducts = entityDetails.products
      listingTitle = `Explore ${entityName} adventures`
      break
    case 'THINGS_TO_DO':
      slicedProducts = entityDetails.products
      listingTitle = `Explore ${entityName}`
      break
    default:
      slicedProducts = entityDetails.products?.slice(0, 12)
      listingTitle = `Top adventures`
  }

  return (
    <Container spacing="XXL">
      <FiltersListingPreview
        entityType={entityType as FiltersListingPreviewEntityType}
        products={slicedProducts}
        regions={regions}
        changeUrl
        categories={categories}
        title={listingTitle}
        handlerKey={handlerKey}
      />
      <Spacing />
    </Container>
  )
}

export const getWidget = (
  widget: TemplateWidget,
  nextWidgetName: string | undefined,
  entityType: string,
  entityDetails: EntityDetails,
  handlerKey?: string,
  region?: string
) => {
  const { name } = widget
  const { name: entityName } = entityDetails

  const searchPath =
    entityType === 'REGION'
      ? `${entityDetails.urlSegment}`
      : entityType === 'HOME'
        ? ''
        : `${region}/${entityDetails.urlSegment}`

  if (name === 'campaign-promo') {
    return (
      <>
        <CampaignPromo
          title={widget.title}
          buttonLabel={widget.description}
          url={widget.link}
          media={widget.image}
          variant="blocks"
        />
        <Spacing size="XXXL" />
      </>
    )
  }

  if (name === 'cta-promo-banner') {
    return (
      <>
        <CampaignPromo
          title={widget.title}
          buttonLabel={widget.cta}
          url={widget.link}
          media={widget.image}
          variant="standard"
        />
        <Spacing size="XXXL" />
      </>
    )
  }

  if (name === 'product-listing') {
    const startIndex = entityDetails.products.length > 4 ? 4 : 0
    const slicedProducts = entityDetails.products?.slice(startIndex, startIndex + 12)

    return (
      <Container>
        <ListingPreview
          isRecommended={false}
          title={`${entityDetails.name} adventures`}
          products={slicedProducts}
          viewMore={{ label: 'See all adventures', link: `/en/search/${searchPath}` }}
          handlerKey={handlerKey}
          toggleProductListViewed={true}
        />
        <Spacing size="XXL" />
      </Container>
    )
  }

  if (name === 'page-description') {
    let seoTitle
    let description
    switch (entityType) {
      case 'REGION':
        seoTitle = `Explore the best of ${entityName}`
        description = entityDetails.description
        break
      case 'CATEGORY':
        seoTitle = `Discover ${entityName}`
        description = entityDetails.description
        break
      case 'SUBCATEGORY':
        seoTitle = `Discover the best of ${entityName}`
        description = entityDetails.description
        break
      case 'HOME':
        seoTitle = ``
        description = widget.text
        break
      default:
        seoTitle = `Discover ${entityName}`
        description = entityDetails.description
    }
    if (!entityDetails.description) return <></>
    return (
      <>
        <Seo heading={seoTitle} content={description} />
      </>
    )
  }

  if (
    [
      'dynamic-product-carousel',
      'static-product-carousel',
      'region-product-carousel',
      'category-product-carousel',
      'contextual-dynamic-product-carousel',
    ].includes(name)
  ) {
    const productWidget = widget as {
      name: string
      label: string
      products: WidgetProduct[]
      variant: WidgetCarouselVariant
    }
    const regionTitle = 'Top adventures'
    const categoryTitle = 'Top adventures'
    const variant: ProductCollectionStyle = productWidget.variant === 'large' ? 'colored' : 'default'

    return (
      <>
        <ProductCollection
          id={productWidget.label}
          sectionTitle={
            entityType === 'REGION' ? regionTitle : entityType === 'THINGS_TO_DO' ? categoryTitle : productWidget.label
          }
          products={toProduct(productWidget.products?.slice(0, 10))} // PLAT-1543: Only show 10 top products
          showCounter
          variant={variant}
        />
        <Spacing size="XXXL" />
      </>
    )
  }

  if (name === 'heading') {
    return (
      <Container>
        <Heading size="XL">{widget.title}</Heading>
        <Spacing size="XXL" />
      </Container>
    )
  }

  if (name === 'home-banner-minimal') {
    return (
      <>
        <HomeHero heroSlides={widget.banners} title={widget.title} subtitle={widget.subtitle} />
        <Spacing size="XXL" />
      </>
    )
  }

  if (name == 'region-carousel') {
    return (
      <>
        <LargeTileSwiper tiles={widget.regions} variant={widget.label} sectionTitle={widget.label} />
        <Spacing size="XXL" />
      </>
    )
  }

  if (name === 'category-grid') {
    const noSpaceNextWidgets = [
      'dynamic-product-carousel',
      'static-product-carousel',
      'region-product-carousel',
      'category-product-carousel',
      'contextual-dynamic-product-carousel',
    ]

    const shouldAddSpacing = shouldAddWidgetSpacing(nextWidgetName, noSpaceNextWidgets)

    return (
      <>
        <FeaturedLinksSwiper tiles={widget.categories} sectionTitle={widget.label} />
        {shouldAddSpacing && <Spacing size="XXXL" />}
      </>
    )
  }

  if (name === 'blog-pages-carousel') {
    return (
      <>
        <EditorialSwiper blogs={widget.blogPages} />
        <Spacing size="XXL" />
      </>
    )
  }

  if (name === 'hero-image') {
    const isCategoryPlp = entityType == 'CATEGORY' || entityType == 'SUBCATEGORY'
    const defaultTitle = isCategoryPlp ? `${entityName} in ${entityDetails?.regionName ?? ''}` : entityName
    return <PlpHeader title={widget.title ?? defaultTitle} text={entityDetails.summary} image={widget.imageUrl} />
  }

  if (name === 'contextual-category-carousel') {
    const tiles = widget.categories.map((category) => {
      return { urlSegment: category.link, name: category.name, image: category.image }
    })
    return (
      <>
        <CustomLinksSwiper
          tiles={tiles}
          variant={widget.label}
          sectionTitle={widget.label}
          urlPrefix="en/adventures/"
          name={name}
        />
        <Spacing size="XXXL" />
      </>
    )
  }

  if (name === 'featured-product-tabs') {
    return (
      <>
        {entityType === 'HOME' && <div mcp-id="featured-product-tabs"></div>}
        <FeaturedProductTabs tabs={widget.tabs} title={widget.title} />
        <Spacing size="XXXL" />
      </>
    )
  }

  if (name == 'content-contributor-showcase') {
    return (
      <>
        <ContributorShowcase title={widget.title} contributors={widget.contributors} />
        <Spacing size="XXXL" />
      </>
    )
  }

  if (name === 'region-links') {
    return (
      <Container spacing="XXL">
        <RegionLinks states={widget.states} region={entityDetails.urlSegment} title={widget.title} />
      </Container>
    )
  }
}

export const getBlogWidget = (templateData: BlogTemplateData, nextWidget: BlogTemplateData | undefined) => {
  const { name } = templateData

  if (name === 'blog-header') {
    return (
      <BlogHero
        title={getBlogAttribute(templateData.attributes, 'title') || ''}
        label={getBlogAttribute(templateData.attributes, 'subtitle') || ''}
        type="imageForeground"
      />
    )
  }

  if (name === 'image') {
    return (
      <Container size="narrow">
        <BlogImage image={getBlogAttribute(templateData.attributes, 'image') || ''} />
      </Container>
    )
  }

  if (name === 'introduction') {
    return (
      <Container size="narrow">
        <Body size="M" as="div" margin>
          <div dangerouslySetInnerHTML={{ __html: getBlogAttribute(templateData.attributes, 'text') || '' }}></div>
        </Body>
      </Container>
    )
  }

  if (name === 'heading') {
    return (
      <Container size="narrow">
        <Spacing size="M" />
        <Heading size="L" as="h2" weight="heading" margin>
          {getBlogAttribute(templateData.attributes, 'text')}
        </Heading>
      </Container>
    )
  }

  if (name === 'sub-heading') {
    return (
      <Container size="narrow">
        <Heading size="M" as="h3" weight="heading" margin>
          {getBlogAttribute(templateData.attributes, 'text')}
        </Heading>
      </Container>
    )
  }

  if (name === 'paragraph') {
    return (
      <Container size="narrow">
        <Body size="M" as="div" margin>
          <div dangerouslySetInnerHTML={{ __html: getBlogAttribute(templateData.attributes, 'text') || '' }}></div>
        </Body>
      </Container>
    )
  }

  if (name === 'promo-image') {
    return (
      <Container size="narrow">
        <PromoImage
          image={getBlogAttribute(templateData.attributes, 'image') || ''}
          heading={getBlogAttribute(templateData.attributes, 'heading') || ''}
          link={getBlogAttribute(templateData.attributes, 'link') || ''}
          cta={getBlogAttribute(templateData.attributes, 'cta') || ''}
        />
      </Container>
    )
  }

  if (name === 'blog-details') {
    return (
      <Container size="narrow">
        <BlogDetails
          author={getBlogAttribute(templateData.attributes, 'author') || ''}
          date={getBlogAttribute(templateData.attributes, 'date') || ''}
          readtime={getBlogAttribute(templateData.attributes, 'readtime') || ''}
        />
        <Spacing />
      </Container>
    )
  }

  if (name === 'image-fan') {
    return (
      <Container size="narrow">
        <ImageFan
          first={getBlogAttribute(templateData.attributes, 'first') || ''}
          second={getBlogAttribute(templateData.attributes, 'second') || ''}
          third={getBlogAttribute(templateData.attributes, 'third') || ''}
        />
      </Container>
    )
  }

  if (name === 'image-grid-four') {
    return (
      <Container size="narrow">
        <ImageGridFour
          first={getBlogAttribute(templateData.attributes, 'first') || ''}
          second={getBlogAttribute(templateData.attributes, 'second') || ''}
          third={getBlogAttribute(templateData.attributes, 'third') || ''}
          fourth={getBlogAttribute(templateData.attributes, 'fourth') || ''}
        />
      </Container>
    )
  }

  if (name === 'image-grid-three') {
    return (
      <Container size="narrow">
        <ImageGridThree
          first={getBlogAttribute(templateData.attributes, 'first') || ''}
          second={getBlogAttribute(templateData.attributes, 'second') || ''}
          third={getBlogAttribute(templateData.attributes, 'third') || ''}
        />
      </Container>
    )
  }

  if (name === 'content-block-left') {
    return (
      <ImageTextBlock imageAlignment="left" image={getBlogAttribute(templateData.attributes, 'image') || ''}>
        <Heading size="M">{getBlogAttribute(templateData.attributes, 'heading') || ''}</Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: getBlogAttribute(templateData.attributes, 'text') || '',
          }}
        ></div>
      </ImageTextBlock>
    )
  }

  if (name === 'content-block-right') {
    return (
      <ImageTextBlock imageAlignment="right" image={getBlogAttribute(templateData.attributes, 'image') || ''}>
        <Heading size="M">{getBlogAttribute(templateData.attributes, 'heading') || ''}</Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: getBlogAttribute(templateData.attributes, 'text') || '',
          }}
        ></div>
      </ImageTextBlock>
    )
  }

  if (name === 'cta-button') {
    return (
      <Container size="narrow">
        <CtaButton
          link={getBlogAttribute(templateData.attributes, 'link') || ''}
          text={getBlogAttribute(templateData.attributes, 'text') || ''}
        />
      </Container>
    )
  }

  if (name === 'content-contributor-header') {
    const data = templateData as Extract<BlogTemplateData, { name: 'content-contributor-header' }>
    return (
      <ContentContributorHeader
        backgroundImage={data.backgroundImage}
        name={data.contributorName}
        description={data.description}
        contributorImage={data.contributorImage}
        quote={data.quote}
        images={data.images}
      />
    )
  }

  if (['static-product-carousel', 'region-product-carousel', 'category-product-carousel'].includes(name)) {
    const data = templateData as Extract<BlogTemplateData, { name: 'static-product-carousel' }>
    return (
      <>
        <ProductCollection
          id={data.label}
          sectionTitle={data.label}
          products={toProduct(data.products)}
          showCounter
          variant="default"
        />
        <Spacing size="XXXL" />
      </>
    )
  }
}

export function getBlogAttribute(attributes: BlogWidgetAttribute[] = [], attributeName: string): string | null {
  return attributes?.find((attribute) => attribute.name === attributeName)?.value || null
}

export const getNextWidgetName = (widgets: TemplateWidget[], currentIndex: number): string | undefined => {
  return widgets[currentIndex + 1]?.name
}

const shouldAddWidgetSpacing = (nextWidgetName: string | undefined, noSpacingWidgets: string[]) => {
  return !nextWidgetName || !noSpacingWidgets.includes(nextWidgetName)
}
