import styled from 'styled-components'
import Link from 'next/link'

export const ButtonWrap = styled.div<{ truncated: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  button[disabled],
  button[disabled]:hover {
    background: transparent;
    color: var(--brand-secondary);
    border-color: var(--brand-secondary);
    cursor: default;
  }

  ${({ truncated }) =>
    truncated &&
    `
    button:nth-child(n+15) {
      display: none;
    }
    
    @media (max-width: 767px) {
      button:nth-child(n+9) {
        display: none;
      }
    }
  `}
`

export const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const ViewAll = styled(Link)`
  color: var(--brand-secondary);
  font-weight: var(--font-bold);
  font-size: 17px;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const ShowMore = styled.div`
  font-weight: var(--font-bold);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 10px 0;
    font-size: 13px;
  }
`
