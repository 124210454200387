import styled from 'styled-components'
import { Tab } from '@headlessui/react'
import Link from 'next/link'
import { BREAKPOINT_SMALL } from '@big-red-group/storefront-common-ui'
import { Z_INDEX } from '@/constant/ui'

export const TabButton = styled(Tab)`
  border: none;
  outline: none;
  background: transparent;
  padding: 12px 30px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  color: var(--text-dark);
  font-size: 18px;
  font-family: var(--font-regular);
  border-bottom: 2px solid transparent;

  &:hover {
    color: var(--text-primary-light);
  }

  &[aria-selected='true'] {
    background: var(--brand-primary);
    font-family: var(--font-medium);
    border-bottom-color: var(--brand-secondary);
    color: var(--text-dark);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 16px;
    padding: 12px 15px;
  }
`

export const TabList = styled(Tab.List)`
  display: flex;
  border-bottom: 1px solid var(--grey-100);
  margin-bottom: 30px;
  row-gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: calc(100% + 24px);
    margin-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    flex-wrap: unset;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const TabContainer = styled(Tab.Panel)`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: black;
  font-family: var(--font-regular);

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 12px;
  }
`

export const RegionButton = styled.button`
  display: inline-flex;
  position: relative;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--brand-accent-secondary-dark);
  background: var(--bg-white);
  cursor: pointer;
  font-size: 16px;

  &:hover {
    border-color: var(--brand-secondary);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 12px;
  }
`
