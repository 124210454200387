import { SearchTrigger, Wrapper } from '@/components/MobileSearchTrigger.styles'
import Icon from '@/components/Icons'
import React from 'react'
import { Body } from '@big-red-group/storefront-common-ui'
import Container from './Container'
import { useElementClick } from '@/hooks/HTMLElementClickHook'

const MobileSearchTrigger: React.FC = () => {
  const searchTitle = 'Search by location or adventure'
  const trackLinkClicked = useElementClick('Search')

  const openSearchModal = (event: any) => {
    const searchInput = document.getElementById('searchInput')
    if (!searchInput) return
    searchInput.click()
    trackLinkClicked({ location: `Mobile Search | ${searchTitle}`, event })
  }
  return (
    <Wrapper>
      <Container>
        <SearchTrigger onClick={(event) => openSearchModal(event)}>
          <Icon name="search" />
          <Body color="text-primary-light">{searchTitle}</Body>
        </SearchTrigger>
      </Container>
    </Wrapper>
  )
}
export default MobileSearchTrigger
