import React from 'react'
import { Tab } from '@headlessui/react'
import { WidgetRegionLink } from '@/types'
import { TabButton, TabList, TabContainer, StyledLink, RegionButton } from './RegionLinks.styles'
import { useRouter } from 'next/router'
import { HeadingWrap, ViewAll } from './SubcategoryBar.styles'
import { formatRedirectLinkLabels } from '@/helpers/ThingsToDoHelper'
import { Button, FlexibleHeading as Heading, Spacing } from '@big-red-group/storefront-common-ui'

type RegionLinkProps = {
  states: { name: string; link: string; regions?: WidgetRegionLink[] }[]
  region: string
  title?: string
}

const RegionLinks: React.FC<RegionLinkProps> = ({ states, region, title = 'Regions to explore' }) => {
  const router = useRouter()
  const { lang, part1, part2, part3 } = router.query
  const pathname = router.route
  const isThingsToDo = pathname.includes('adventures')

  let relativeThingsToDoURL = `/${lang}/adventures/${part2}/${part3}`
  relativeThingsToDoURL = relativeThingsToDoURL.replaceAll('/undefined', '')

  let redirectURL = (region: string) => {
    let url = isThingsToDo ? `/${lang}/${region}/${part1!}/${part2!}` : `/${lang}/${region}/${part2!}/${part3!}`
    return url.replaceAll('/undefined', '')
  }

  const isCurrentRegion = (region: string) => {
    let currentRegion = part1 as string
    currentRegion = currentRegion.toLowerCase().replaceAll('-', '')
    return region.replaceAll(' ', '').toLowerCase() === currentRegion
  }

  // TO remove the current region and state from the other regions option
  if (!isThingsToDo) {
    states?.forEach((state) => {
      state.regions = state.regions?.filter((region) => !isCurrentRegion(region.name))
    })

    // to remove the state if it had only empty regions - e.g theme-parks available only in gold-coast
    states = states?.filter((state) => state && state.regions && state.regions?.length > 0)
  }

  return states && states.length > 0 ? (
    <>
      <HeadingWrap>
        <Heading as="h2" size="L">
          {title}
        </Heading>
        {/* {!isThingsToDo && (
          <ViewAll data-testid="view-all" href={`${relativeThingsToDoURL}`}>
            View all{' '}
            {part3
              ? formatRedirectLinkLabels((part3 + ' ' + part2) as string)
              : formatRedirectLinkLabels(part2 as string)}
          </ViewAll>
        )} */}
      </HeadingWrap>
      <Spacing size="S" />
      <Tab.Group>
        <TabList>
          {/* <TabButton>All</TabButton> */}
          {states?.map(({ regions, name }) =>
            regions ? <TabButton key={name}>{name == 'Australian Capital Territory' ? 'ACT' : name}</TabButton> : null
          )}
        </TabList>
        <Tab.Panels>
          {/* Disabled for now as currently all states have regions  */}
          {/* <TabContainer>
            {states.map((state, index) => {
              if (!state.regions) {
                return (
                  <Button size="S" onClick={() => router.push(`/${lang}/${state}`)} key={index}>
                    {state.name}
                  </Button>
                )
              }
              return null
            })}
          </TabContainer> */}
          {states?.map((state) => {
            if (state.regions) {
              return (
                <TabContainer key={state.name}>
                  {state.regions.map((region, index) => (
                    <>
                      {!isCurrentRegion(region.name) && (
                        <RegionButton onClick={() => router.push(redirectURL(region.link))} key={index}>
                          {region.name}
                        </RegionButton>
                      )}
                    </>
                  ))}
                </TabContainer>
              )
            }
            return null
          })}
        </Tab.Panels>
      </Tab.Group>
      <div style={{ marginTop: '30px' }}>
        <StyledLink href={`/${lang}/search/${region}`}>See more locations</StyledLink>
      </div>
    </>
  ) : (
    <></>
  )
}

export default RegionLinks
